<template>
	<div id="details" class="deta">
		<!-- <div class="h1">{{cideData.title}}</div>
		<div class="html" v-html="cideData.content"></div> -->
		
		<div v-if="Loadings" class="Loadings">
			<van-loading size="24px" vertical>
				<div>加载中...</div>
			</van-loading>
		</div>
		<div class="top" v-if="!te404">
			<div class="cat">{{cat}}</div>
			<div class="title">{{title}}</div>
			<div class="typeText">文章主题：{{subject}}</div>
		</div>
		
		<!-- li_yes -->
		<div class="contS" v-if="!te404">
			<div  class="h1">查看译文</div>
			<!-- :style="'color:'+colorData[i%7].c+';background:'+colorData[i%7].b+';'" -->
			<div class="ul">
				<div  :class="'li '+ (sybData[i] ==true?'li_yes':'')" v-for="(item,i) in itemList"  @click="go(i)" >{{item.name}}</div>
			</div>
		</div>
		<img v-if="te404" src="../assets/404.svg" alt="">
	</div>
	
</template>

<script scoped="scoped">
	import {
		Notify,
		Cell,
		CellGroup,
		Field,
		Icon,
		Loading,
	} from 'vant';

	import https from '../https.js'
	export default {
		components: {
			[Notify.name]: Notify,
			[Cell.name]: Cell,
			[CellGroup.name]: CellGroup,
			[Field.name]: Field,
			[Icon.name]: Icon,
			[Loading.name]: Loading,
		},
		data() {
			return {
				html: "",
				Loadings: false,
				te404:false,
				cideData: {},
				colorData:[
					{
						c:"#FF4B5F",
						b:"#FFE4E7"
					},
					{
						c:"#FF814B",
						b:"#FFF1E4"
					},
					{
						c:"#FFC300",
						b:"#FFFCE4"
					},
					{
						c:"#66AE8A",
						b:"#EBFFEC"
					},
					{
						c:"#4EA5FF",
						b:"#E4ECFF"
					},
					{
						c:"#1FC3E0",
						b:"#E4FAFF"
					},
					{
						c:"#9C5BFB",
						b:"#F5E4FF"
					}
				],
				itemList:[],
				sybData:[],
				
				cat:"",
				title:"",
				subject:"",
				
			}
		},
		created() {
			// localStorage   长期
			// sessionStorage; 短期
			// var storage = window.sessionStorage;
			// storage.setItem('tab', tab);
			// storage.getItem('tab')
			// 2_2020_2
			let id = this.$route.params.id;
			this.id = id;
			console.log(id)
			var that = this;
			that.Loadings = true;
			https.fetchGet('/api/subject/syb/find/' + id, {}).then(res => {
				that.Loadings = false
				if (res.data && res.data.data) {
					var data = res.data.data;
					that.cideData = data
					var itemList = data.itemList
					this.cat = "英语"+this.intToChinese( parseInt(data.cat))
					this.subject = data.subject
					for(var i in itemList){
						itemList[i].name = "第"+this.intToChinese( parseInt(i)+1)+"段"
					}
					if(data.title){
						document.title = data.title
						this.title = data.title
					}
					
					var storage = window.sessionStorage;
					storage.setItem('itemListData_' + id, JSON.stringify(itemList));
					this.itemList = itemList;
					console.log(this.itemList)
				} else {
					that.te404 = true
					Notify({
						type: 'warning',
						message: '内容已删除，请稍后再试！'
					});
				}
			}).catch(err => {
				that.Loadings = false
				that.te404 = true
				Notify({
					type: 'warning',
					message: '当前人数过多，请稍后重试'
				});
				console.log(err)
			})
			// console.log(this.intToChinese(100))
			var storage = window.localStorage;
			var a = storage.getItem('currentSybData_'+this.id);
			console.log("currentSybData_",typeof a,a)
			
			if(a){
				this.sybData = JSON.parse(a)||[]
			}
			
		},
		methods:{
			go(e){
				this.$router.push({
					name: 'subject/syb/details',
					query: {
						id: this.id,
						current:e
					}
				})
			},
			intToChinese ( str ) {
			 str = str+'';
			 var len = str.length-1;
			 var idxs = ['','十','百','千','万','十','百','千','亿','十','百','千','万','十','百','千','亿'];
			 var num = ['十','一','二','三','四','五','六','七','八','九'];
			 return str.replace(/([1-9]|0+)/g,function( $, $1, idx, full) {
			  var pos = 0;
			  if( $1[0] != '0' ){
			   pos = len-idx;
			   if( idx == 0 && $1[0] == 1 && idxs[len-idx] == '十'){
			    return idxs[len-idx];
			   }
			   return num[$1[0]] + idxs[len-idx];
			  } else {
			   var left = len - idx;
			   var right = len - idx + $1.length;
			   if( Math.floor(right/4) - Math.floor(left/4) > 0 ){
			    pos = left - left%4;
			   }
			   if( pos ){
			    return idxs[pos] + num[$1[0]];
			   } else if( idx + $1.length >= len ){
			    return '';
			   }else {
			    return num[$1[0]]
			   }
			  }
			 });
			}
		}
	}
</script>

<style >
	.contS{
		width: 690px;
		border-radius: 28px;
		margin: 30px auto;
		background-color: #fff;
		padding-bottom: 16px;
	}
	.cat{
		padding: 9px 21px;
		background-color: #5EA586;
		border-radius: 24px;
		font-size: 26px;
		display: inline-block;
		margin-top: 43px;
		line-height: 26px;
	}
	.title{
		font-size: 54px;
		font-weight: 500;
		color: #FFFFFF;
		line-height: 54px;
		margin-top: 30px;
	}
	.top{
		width: 690px;
		/* height: 276px; */
		margin: 30px auto;
		border-radius: 28px;
		background: url(../assets/b3.png) no-repeat #fff;
		background-size:100% 100%;
		padding: 0 40px 40px;
		box-sizing: border-box;
		color: #fff;
	}
	.typeText{
		font-size: 22px;
		font-weight: 500;
		color: #FFFFFF;
		line-height: 22px;
		line-height: 30px;
		margin-top: 43px;
	}
	.deta{
		background: #F8F8F8;
		background-size:100%;
		overflow: hidden;
		min-height: 100vh;
	}
	
	#details {
		max-width: 750px;
		margin: 0 auto;
	}

	.html {
		padding: 0 30px;
		text-align: justify;
		word-wrap: break-word;
		/* word-break: break-all; */
		
	}
	.h1 {
		padding: 32px 30px;
		font-size: 36px;
		display: flex;
		align-items: center;
		justify-content: center;
		color: #FFB753;
	}
    .h1:before{
	  content:" ";
	  width: 88px;
	  height: 4px;
	  display: block;
	  background: linear-gradient(90deg, rgba(126, 180, 156, 0) 0%, #FFB753 100%);
	  border-radius: 2px;
	  margin-right: 20px;
	
	}
	.h1:after{
	  content:" ";
	  width: 88px;
	  height: 4px;
	  display: block;
	  background: linear-gradient(270deg, rgba(126, 180, 156, 0) 0%, #FFB753 100%);
	  border-radius: 2px;
	  margin-left: 20px;
	}
	.Loadings {
		width: 100%;
		height: 100%;
		position: fixed;
		left: 0;
		top: 0;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.Loadings .van-loading {
		padding: 20px;
		background-color: rgba(0, 0, 0, 0.6);
		border-radius: 10px;
		color: #fff;
	
	}
	
	.Loadings .van-loading >div {
		color: #fff;
	}
	.ul{
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		padding: 0 56px ;
	}
	.li{
		
		width: 266px;
		height: 128px;
		background: #FFB753;
		border-radius: 28px;
		/* margin: 40px ; */
		line-height: 128px;
		text-align: center;
		font-size: 34px;
		color: #fff;
		margin-bottom: 40px;
	}
	.li_yes{
		opacity: 0.7;
	}
</style>
